<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <div v-if="loaded">
      <b-card v-if="loaded"
              :title="(USER.has_google_oauth ? $t('Disconnect your google account') : $t('Connect your google account'))">
        <b-button
            v-if="!USER.has_google_oauth"
            class="mt-2"
            variant="success"
            type="submit"
            @click="handleClickLogin"
        >
          {{ $t("Connect your google account") }}
        </b-button>
        <b-button
            v-if="USER.has_google_oauth"
            class="mt-2"
            variant="success"
            type="submit"
            @click="disconnectGoogleAccount"
        >
          {{ $t("Disconnect your google account") }}
        </b-button>
      </b-card>

      <b-overlay
          v-if="loaded && this.USER.has_google_oauth"
          id="overlay-background"
          :show="!calendarItemsLoaded"
          variant="dark"
          opacity="0.85"
          blur="2px"
          rounded="sm"
      >
        <b-card :title="$t('Choose your google calendar')">
          <p>{{
              $t('Please select a Google calendar with which you want to synchronize your events and appointments.')
            }}</p>
          <b-form-select
              v-model="currentItem.calendar"
              :options="calendarItems"
              label-field="summary"
              text-field="summary"
              value-field="id"
              :placeholder="$t('Please select an Calendar')"
              @change="saveCalendar"
          >
          </b-form-select>
        </b-card>
      </b-overlay>
    </div>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormFile,
  BAvatar,
  BMedia,
  BFormSelect,
  BOverlay
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {ref, onUnmounted, mounted, computed} from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";

import {required, alphaNum, email} from "@validations";
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import deLocale from "@fullcalendar/core/locales/de";
import moment from "moment";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormFile,
    BAvatar,
    BMedia,
    BFormSelect,
    BOverlay,

    FullCalendar
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      loaded: false,
      calendarItemsLoaded: false,
      calendarItems: null,
      currentItem: {
        calendar: null,
      },
    }
  },

  mounted() {
    this.GET_USER()

    let googleScript = document.createElement('script')
    googleScript.setAttribute('src', 'https://accounts.google.com/gsi/client')
    document.head.appendChild(googleScript)
  },
  watch: {
    USER() {
      this.loaded = true
      if (this.USER.has_google_oauth) {
        this.getCalendars()
      }
    },
  },

  computed: {
    ...mapGetters(['USER']),
  },


  methods: {
    ...mapActions(['GET_USER']),
    handleClickLogin() {
      this.initClient()
    },
    initClient() {
      console.debug(google)
      let access_token;

      let client = google.accounts.oauth2.initCodeClient({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        scope: process.env.VUE_APP_GOOGLE_SCOPE,
        ux_mode: 'popup',
        callback: (response) => {
          this.saveAuthCode(response.code)
        },
      });

      client.requestCode();
    },
    saveAuthCode(authCode) {
      this.$http.post('/googleoauth', {authcode: authCode})
          .then(response => {
            this.$bvToast.toast(this.$i18n.t('Google account successfully connected'), {
              title: this.$i18n.t('Success'),
              variant: 'success',
              toaster: 'b-toaster-bottom-right'
            })

            this.USER.has_google_oauth = true
            this.getCalendars()

            // this.$router.push({name: routeName})
          })
          .catch(e => {
            this.$bvToast.toast(this.$i18n.t('Google account not connected'), {
              title: this.$i18n.t('Error'),
              variant: 'danger',
              toaster: 'b-toaster-bottom-right'
            })
          });
    },
    getCalendars() {
      this.$http.get('/googlecalendars')
          .then(response => {
            console.log(response.data);
            this.calendarItemsLoaded = true
            this.calendarItems = response.data.items

            this.calendarItems.unshift({
              "id": null,
              "summary": this.$t('Choose your calendar')
            })

            if (response.data.current) {
              this.currentItem.calendar = response.data.current.calendar_id
            }

          })
          .catch(e => {
            this.$bvToast.toast(this.$i18n.t('Item not saved'), {
              title: this.$i18n.t('Error'),
              variant: 'danger',
              toaster: 'b-toaster-bottom-right'
            })
          });
    },
    saveCalendar() {
      let calendarId = (this.currentItem.calendar == "Choose Calendar" ? null : this.currentItem.calendar)
      this.$http.post('/googlecalendars', {calendar_id: this.currentItem.calendar})
          .then(response => {
            this.$bvToast.toast(this.$i18n.t('Calendar connected'), {
              title: this.$i18n.t('Success'),
              variant: 'success',
              toaster: 'b-toaster-bottom-right'
            })
          })
          .catch(e => {
            this.$bvToast.toast(this.$i18n.t('Calendar not connected'), {
              title: this.$i18n.t('Error'),
              variant: 'danger',
              toaster: 'b-toaster-bottom-right'
            })
          });
    },
    disconnectGoogleAccount() {
      this.$http.delete('/googlecalendar')
          .then(response => {
            this.$bvToast.toast(this.$i18n.t('Google account disconnected'), {
              title: this.$i18n.t('Success'),
              variant: 'success',
              toaster: 'b-toaster-bottom-right'
            })

            this.USER.has_google_oauth = false
          })
          .catch(e => {
            this.$bvToast.toast(this.$i18n.t('Google account not disconnected'), {
              title: this.$i18n.t('Error'),
              variant: 'danger',
              toaster: 'b-toaster-bottom-right'
            })
          });
    },
    getCalendarFreeBusy() {
      this.$http.get('/googlecalendar')
          .then(response => {
            this.$bvToast.toast(this.$i18n.t('Calendar saved'), {
              title: this.$i18n.t('Success'),
              variant: 'success',
              toaster: 'b-toaster-bottom-right'
            })
          })
          .catch(e => {
            this.$bvToast.toast(this.$i18n.t('Calendar not saved'), {
              title: this.$i18n.t('Error'),
              variant: 'danger',
              toaster: 'b-toaster-bottom-right'
            })
          });
    }
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
